import './CelebLoves.css';
import cel1 from "./../../Images/CelebLoves/cel1.png";
import cel2 from "./../../Images/CelebLoves/cel2.png";
import cel3 from "./../../Images/CelebLoves/cel3.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const items = [cel1, cel2, cel3];

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 3000,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            arrows: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: false
          }
        }
      ]
  };

const CelebLoves = () => {
    return (
        <div className="CelebLoves">
            <div className="row align-items-center">
                <div className="col-sm-12 col-md-3">
                    <div className="celebs">
                        Celebs
                    </div>
                    <div className="loveus">
                        LOVE US
                    </div>
                </div>
                <div className="col-sm-12 col-md-9">
                    <Slider {...settings}>
                    {items.map((item, index) => <img key={index} src={item} />)}
                </Slider>
                </div>
            </div>
        </div>
    );
}

export default CelebLoves;
