import './CustomerSupportCard.css';
import Card from "../../Images/CustomerSupport/customer-support.png";
import NumberCard from '../NumberCard';
import Fade from 'react-reveal/Fade';

const CustomerSupportCard = () => {
    return (
        <div className="CustomerSupportCard">
            <div className="row align-items-center">

                <div className="col-sm-12 col-md-8 customer-support-section">
                    {/* <img src={topImg1} alt="topImg1" className="w-100" /> */}
                    <div className="customer-support-title">
                    Customer Support
                    </div>
                    <div className="secondCard-description">
                    Get In Touch With Crystal CUSTOMER CARE For Any Queries, Emergencies,
                    </div>
                    <div className="secondCard-description">
                    Feedbacks or Complaints. We Are Here To Help You 24/7 With Our Online Services.
                    </div>
                    <div className="customer-support-service">
                    24 hours service
                    </div>
                    <div className="secondCard-wp-text">
                        Customer Care No. For WhatsApp & Calling
                    </div>
                    <NumberCard />
                </div>
                <div className="col-sm-12 col-md-4">
                    <Fade right>
                        <img src={Card} alt="crystalLogo" className="right-sideImg"/>
                    </Fade>
                </div>
            </div>
        </div>
    );
}

export default CustomerSupportCard;
