import React, { useState} from 'react';
import './App.css';
import crystalLogo from "./Images/crystal-logo.png";
import headerLogo from "./Images/crystal-logo.png";

import topImg1 from "./Images/topImg-1.png";
import SecondCard from './Components/SecondCard';
import LastCard from './Components/LastCard';
import NumberCard from './Components/NumberCard';
import CustomerSupportCard from './Components/CustomerSupportCard';
import CelebLoves from './Components/CelebLoves';
import Instagram from './Components/Instagram';
import GetInTouch from './Components/GetInTouch';
// import Footer from './Components/Footer';
import 'font-awesome/css/font-awesome.min.css';
import Fade from 'react-reveal/Fade';
import Loader from './Components/Loader';

function App() {

  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  return (
    <>
    {isLoading ? <Loader /> :
      <div className="crystal-container">

        <div className="header">
          <img className="header-logo" src={headerLogo}  alt={"crystal-logo"}/>
          <div className="d-flex">
            <a
              href="https://wa.me/918238514444"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className='header-login'>
                <span>
                  Log in
                  <i className="fa fa-sign-in" />
                </span>
              </div>
            </a>
            <a
              href="https://wa.me/918238514444"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className='header-login ml-2'>
                <span>
                  Sign up
                  <i className="fa fa-user" />
                </span>
              </div>
            </a>
          </div>
        </div>

        <div className="row first-section">
          <div className="col-sm-12 col-md-6">
            <div>
              <img src={crystalLogo} alt="crystalLogo" className="crystalLogo" />
              <div className="first-section-logo-text">
                Imandari ek mahanga shok hai jo har kisike bas ki baat nahi hoti.
              </div>
              <div className="first-section-description">
                <div className="first-section-description-2">
                  MOST TRUSTED BOOK EVER
                </div>
              </div>

              <div className="first-section-share-text">
                For New ID WhatsApp us on
              </div>

              <NumberCard isFirstCard/>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <Fade right>
            <img src={topImg1} alt="topImg1" className="w-100 imageRightSide" />
            </Fade>
          </div>
        </div>
        <SecondCard />
        <hr />
        <CustomerSupportCard />
        <hr />
        <LastCard />
        <hr />
        <CelebLoves />
        <hr />
        <Instagram />
        <GetInTouch />
      </div>
      }
    </>
  );
}

export default App;
