import './LastCard.css';
import Card from "../../Images/LastCard/card.png";
import NumberCard from '../NumberCard';
import Fade from 'react-reveal/Fade';

const SecondCard = () => {
    return (
        <div className="secondCard">
            <div className="row align-items-center">
                <div className="col-sm-12 col-md-6 io">
                    <Fade left>
                        <img src={Card} alt="crystalLogo" className="lastCard-img"/>
                        </Fade>
                </div>
                <div className="col-sm-12 col-md-6 text-section">
                    {/* <img src={topImg1} alt="topImg1" className="w-100" /> */}
                    <div className="secondCard-title-2">
                    Place your BET in our IDs
                    </div>
                    <div className="secondCard-description">
                    With Our Step-By-Step Guide For Beginners, 
                    </div>
                    <div className="secondCard-description">
                    Get Started Playing. Start Learning Now.. 24/7 ACTIVE.
                    </div>
                    <div className="secondCard-wp-text">
                        We deals only on WhatsApp
                    </div>
                    <NumberCard />
                </div>
            </div>
        </div>
    );
}

export default SecondCard;
