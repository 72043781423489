import React from 'react';
import './Instagram.css';
import insta1 from "./../../Images/Instagram/insta1.png";
import insta2 from "./../../Images/Instagram/insta2.png";
import insta3 from "./../../Images/Instagram/insta3.png";
import insta4 from "./../../Images/Instagram/insta4.png";
import available1 from "./../../Images/Instagram/site1.png";
import available2 from "./../../Images/Instagram/site2.png";
import available3 from "./../../Images/Instagram/site3.png";
import available4 from "./../../Images/Instagram/available-3.png";
import available5 from "./../../Images/Instagram/site5.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const items = [insta1, insta2, insta3, insta4, insta1];

const availablesItems = [
    {
        image: available3,
        link: "http://www.crystal20.com"
    },
    {
        image: available1,
        link: "http://www.crystal247.com"
    },
    {
        image: available5,
        link: "http://www.crystal365.com"
    },
    {
        image: available2,
        link: "http://www.mrmexch.com"
    },
    {
        image: available4,
        link: "http://mrm777.com"
    }
];

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                arrows: false
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                arrows: false
            }
        }
    ]
};

const Instagram = () => {
    return (
        <div className="CelebLoves">
            <div className="align-items-center">
                <div className="CelebLoves-text">
                    <a class="text-decoration-none" target="_blank" href={"https://instagram.com/crystal_online_book"}><h1>INSTAGRAM</h1></a>
                    {/* <h5>#Follow</h5> */}
                </div>
                <div className="slider-data">
                    <Slider {...settings}>
                        {items.map((item, index) => <a target="_blank" href={"https://instagram.com/crystal_online_book"}><img style={{width: "100%"}} key={index} src={item} alt={"instagram_links"}/></a> )}
                    </Slider>
                </div>

                <div className="available-sites">
                    <h1>AVAILABLE SITES</h1>

                    <div className="row align-items-center justify-content-center">
                        {availablesItems.map((item, index) => {
                            return (
                                <a
                                    key={index}
                                    className="available-items"
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={item.image} />
                                </a>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Instagram;
