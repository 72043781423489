import './Number.css';
// import teligram from "../../Images/teligram.png";
import wp from "../../Images/wp-logo-2.png";

const NumberCard = (props) => {

    const { isOneNumber, number, isFirstCard, isInstagram=false } = props;
    return (
        <>
        <div className='first-section-share-number d-flex'>
            <a
                href={number ? `${'https://wa.me/' + number}` : "https://wa.me/918238514444"}
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <div className={`${isFirstCard ? 'first-cardNumber-container' : 'first-number-container'}`}>
                    <div className={`${isFirstCard ? 'first-card-number' : ''} first-number`}>
                        <img src={wp} className="wp-originalImg"/>
                        {/* <i className="fa fa-whatsapp fa-2x" /> */}
                        {number ? number : '+91 8238514444'}
                    </div>
                </div>
            </a>
            {!isOneNumber &&
                <a
                    href="https://wa.me/918238514444"
                    className="whatsapp_float"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className='second-number-container'>
                    <div className={`${isFirstCard ? 'first-card-number' : ''} first-number`}>
                            <img src={wp} className="wp-originalImg"/>
                            +91 8238514444
                        </div>
                    </div>
                </a>}

            {number &&
              <a
              href="https://t.me/+Eok5sUUrZQ1iZjI1"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
          >
                <div className='second-number-container'>
                        <div className='first-number'>
                            <i className="fa fa-telegram fa-2x" />
                            Join Our Telegram
                        </div>
                    </div>
                    </a>}

{   isInstagram &&
                    <a
              href="https://instagram.com/crystal_online_book"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
          >
                <div className='thired-number-container web_instagram'>
                        <div className='first-number'>
                            <i className="fa fa-instagram fa-2x" />
                            Join Our Instagram
                        </div>
                    </div>
                    </a>
                    }
        </div>
        <div>
            {isInstagram &&
        <a
              href="https://instagram.com/crystal_online_book"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
          >
                <div className='thired-number-container mobile_instagram'>
                        <div className='first-number'>
                            <i className="fa fa-instagram fa-2x" />
                            Join Our Instagram
                        </div>
                    </div>
                    </a>
                    }

        </div>
        </>
    )
};

export default NumberCard;
