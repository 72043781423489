import './SecondCard.css';
import Card from "../../Images/SecondCard/card.png";
import NumberCard from '../NumberCard';
import Fade from 'react-reveal/Fade';

const SecondCard = () => {
    return (
        <div className="secondCard">
            <div className="row align-items-center">
                <div className="col-sm-12 col-md-6 secondCard-image-section">
                    <div>
                    <Fade left>
                        <img src={Card} alt="crystalLogo" className="imageLeftSide"/>
                        </Fade>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 secondCard-text-section">
                    {/* <img src={topImg1} alt="topImg1" className="w-100" /> */}
                    <div className="secondCard-title">
                        The Punter
                    </div>
                    <div className="secondCard-title-2">
                        FRIENDLY BOOK
                    </div>
                    <div className="secondCard-description">
                        We Deals With All Branded Sites, And Present
                    </div>
                    <div className="secondCard-description">
                        The Fastest Withdrawal Service.
                    </div>
                    <div className="secondCard-wp-text">
                        We deals only on WhatsApp
                    </div>
                    <NumberCard />
                </div>
            </div>
        </div>
    );
}

export default SecondCard;
