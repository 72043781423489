import './GetInTouch.css';
import Card from "../../Images/GetIntouch/img1.png";
import NumberCard from '../NumberCard';
import Fade from 'react-reveal/Fade';

const SecondCard = () => {
    return (
        <div className="secondCard">
            <div className="row align-items-center">
                <div className="col-sm-12 col-md-6">
                    <Fade left>
                        <img src={Card} alt="crystalLogo" className="getTouchImg imageRightSide"/>
                    </Fade>
                </div>
                <div className="col-sm-12 col-md-6 secondCard-text-section">
                    {/* <img src={topImg1} alt="topImg1" className="w-100" /> */}
                    <div className="secondCard-title">
                    Get In Touch With
                    </div>
                    <div className="secondCard-title-2">
                     Crystal Customer Care
                    </div>
                    <div className="secondCard-description">
                    For Any Queries, Emergencies, Feedbacks or Complaints. We Are Here To Help
                    </div>
                    <div className="secondCard-description">
                    You 24/7 With Our Online Services.
                    </div>
                    <div className="whatsapp-on">
                    Whatsapp us on
                    </div>
                    <NumberCard />
                    <div className="whatsapp-on">
                    Customer care number
                    </div>
                    <NumberCard isOneNumber={true} number='918238514444' isInstagram={true}/>
                </div>
            </div>
        </div>
    );
}

export default SecondCard;
